import React, { useContext } from "react";
import ContextService from "../../../../context/ContextService";
import Conference from "./Conference";
import Accomodation from "./Accomodation";
import Transport from "./Transport";
import Sport from "./Sport";
import Restaurant from "./Restaurant";
import Cultural from "./Cultural";

export default function AllServices() {
  const { service } = useContext(ContextService);

  switch (service) {
    case "CONFERENCE":
      return <Conference />;

    case "ACCOMODATION":
      return <Accomodation />;

    case "TRANSPORT":
      return <Transport />;

    case "SPORT":
      return <Sport />;

    case "RESTAURANT":
      return <Restaurant />;

    case "CULTURAL":
      return <Cultural />;

    default:
      return null;
  }
}

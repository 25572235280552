import React from "react";
import lovasoa_flm from "../../../../assets/images/activities/lovasoa_flm.jpeg";
import lovasoa_flm_2 from "../../../../assets/images/activities/lovasoa_home_3.jpeg";

export default function Archive() {
  return (
    <React.Fragment>
      <section
        id="archive"
        className="archive "
        style={{
          bottom: "60px",
          top: "60px",
          left: 0,
          right: 0,
        }}
      >
        <div className="container" data-aos="fade-up">
          <div className="activities-details-title">
            <h2>activity</h2>
            <p>ARCHIVE</p>
          </div>
          <div className="row no-gutters">
            <div className="content col-xl-5 d-flex align-items-stretch">
              <div className="content px-3">
                <h3>Delve into archival treasures and rare manuscripts</h3>
                <p>
                  that illuminate the tapestry of the Malagasy Lutheran Church
                  and Norwegian history with every turn of the page. Central to
                  the preservation efforts is the L4C project strategy, ensuring
                  the long-term safeguarding of these vital records through
                  digitalization. This initiative aims not only to facilitate
                  access to information locally and internationally, but it also
                  seeks to foster collaboration with other archival
                  institutions.
                </p>
              </div>
            </div>
            <div className="col-xl-7 d-flex align-items-stretch">
              <div className="icon-boxes d-flex flex-column justify-content-center">
                <div className="row">
                  <div
                    className="col-md-6 icon-box"
                    data-aos="fade-up"
                    data-aos-delay="100"
                  >
                    <i className="bx bx-church"></i>
                    <h4>The Malagasy Lutheran Church archives</h4>
                    <p>
                      housed within the Cultural Centre at L4C, highlight the
                      profound historical connection between the Malagasy
                      Lutheran Church, the Norwegian Missionaries and
                      Madagascar's development.
                    </p>
                  </div>
                  <div
                    className="col-md-6 icon-box"
                    data-aos="fade-up"
                    data-aos-delay="200"
                  >
                    <i className="bx bx-cube-alt"></i>
                    <h4>The archives stored at L4C</h4>
                    <p>
                      are meticulously organized and continuously expanding,
                      offer a wealth of original texts, manuscripts, photos and
                      microfilms through diaries, correspondence, reports,
                      mission journals and accounting books.
                    </p>
                  </div>
                  <div
                    className="col-md-6 icon-box"
                    data-aos="fade-up"
                    data-aos-delay="300"
                  >
                    <i className="bx bx-images"></i>
                    <h4>These archives</h4>
                    <p>
                      are a treasure of records documenting the Norwegian
                      Mission Society's (NMS) impactful work, fostering social
                      inclusion and economic development. Such contributions
                      have left an indelible mark on Madagascar's history,
                      making these archives invaluable to understanding its
                      past.
                    </p>
                  </div>
                  <div
                    className="col-md-6 icon-box"
                    data-aos="fade-up"
                    data-aos-delay="400"
                  >
                    <i className="bx bx-shield"></i>
                    <h4>These resources</h4>
                    <p>
                      complemented by detailed catalogues, provide researchers
                      with efficient access to a rich tapestry of historical
                      information. Through meticulous categorization and
                      digitalization efforts, L4C ensures the proper
                      documentation and sustainable preservation of the history
                      and memories of the Malagasy Lutheran Church and the
                      Norwegian Mission.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="gallery" style={{ marginTop: "-4rem" }}>
        <div className="container-fluid">
          <div className="row gy-0 gx-3 px-1 px-lg-5">
            <div className=" col-md-5 col-sm-12">
              <div className="gallery-item">
                <img
                  src={lovasoa_flm}
                  alt="lovasoa_flm"
                  className="img-fluid"
                />
              </div>
            </div>

            <div className=" col-md-7 col-sm-12">
              <div className="gallery-item">
                <img src={lovasoa_flm_2} alt="" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

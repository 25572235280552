import React from "react";

export default function Cultural() {
  return (
    <React.Fragment>
      {/* MALAGASY CUTURAL EVENTS */}
      <section className="cultural activities-details">
        <div className="container">
          <div className="activities-details-title" data-aos="zoom-out">
            <p>MALAGASY CUTURAL EVENTS </p>
            <div className="mt-3">
              <span>
                Experience the vibrant tapestry of Malagasy culture come alive
                in our exhilarating cultural events. From traditional music and
                dance to culinary delights immerse yourself in the rich heritage
                of Madagascar and forge lasting connections with its people
                through the Cultural Evenings, Discussions, and Events. The
                cultural evenings offers a package of dinner and show that
                allows you to experiment the Malagasy Culture in its artistic
                side. The cultural discussions will allow you to dig deeper on a
                specific topic with locals that speak English in a safe
                environment. The cultural events aim at being a hub to enjoying
                talents and making encounters that will foster connection
                between different cultures.
              </span>
            </div>
          </div>
        </div>
      </section>
      {/* Fin MALAGASY CUTURAL EVENTS */}
    </React.Fragment>
  );
}

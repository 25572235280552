import React, { useContext, useState } from "react";
import ContextService from "../../../../context/ContextService";

export default function ForVisitor() {
  // IMPLEMENT USE-CONTEXT
  const { loadingSend, saveBooking, bookingFormRef } =
    useContext(ContextService);

  // STATES -----------------------
  const [arrivalDateBooking, setArrivalDateBooking] = useState(null);

  // today
  const today = new Date();

  return (
    <React.Fragment>
      <section className="activities-archives"></section>
      <section id="contact" className="contact">
        <div className="container">
          <div className="section-title">
            <h2>Booking form</h2>
            <p>
              Please complete the form below <br />
              <br /> Your registration will be verified prior to your arrival.
            </p>
          </div>
        </div>

        <div className="container">
          <div className="row mt-4">
            <div className="col-lg-9 mt-5 mt-lg-0 mx-auto">
              <div class="info-wrap">
                <div class="row">
                  <div class="col-md-4 info">
                    <i class="bi bi-geo-alt"></i>
                    <h4>Location:</h4>
                    <p>
                      Lot 01 E 30 Rue Stavanger
                      <br />
                      BP49, Antsirabe
                    </p>
                  </div>

                  <div class="col-md-4 info mt-4 mt-lg-0">
                    <i class="bi bi-envelope"></i>
                    <h4>Email:</h4>
                    <p>guesthouse@lovasoa.mg</p>
                  </div>

                  <div class="col-md-4 info mt-4 mt-lg-0">
                    <i class="bi bi-phone"></i>
                    <h4>Call:</h4>
                    <p>
                      (+261) 34 05 045 25
                      <br />
                      (+261) 20 44 486 85
                    </p>
                  </div>
                </div>
              </div>
              <form
                ref={bookingFormRef}
                // method="post"
                onSubmit={saveBooking}
                // role="form"
                className="php-email-form"
              >
                {/* FULL NAME */}
                <div className="row">
                  <label htmlFor="firstname">CLIENT INFORMATION</label>
                  <div className="col-md-6 form-group">
                    <input
                      type="text"
                      name="firstname"
                      className="form-control"
                      id="firstname"
                      placeholder="First name"
                      required
                    />
                  </div>
                  <div className="col-md-6 form-group">
                    <input
                      type="text"
                      name="lastname"
                      className="form-control"
                      id="lastname"
                      placeholder="Last name"
                      required
                    />
                  </div>
                </div>
                {/* END FULL NAME */}
                {/* CONTACT: phone number and email */}
                <div className="row mt-3">
                  <label htmlFor="phone">CLIENT CONTACT</label>
                  <div className="col-md-6 form-group mt-md-0">
                    <input
                      type="tel"
                      className="form-control"
                      name="phone"
                      id="phone"
                      placeholder="Enter Phone Number"
                      required
                    />
                  </div>
                  <div className="col-md-6 form-group mt-md-0">
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      id="email"
                      placeholder="Enter Email"
                      required
                    />
                  </div>
                </div>
                {/* END CONTACT: phone number and email */}

                {/* ADDRESS */}
                <div className="row mt-3">
                  <label htmlFor="streetAddress">CLIENT ADDRESS</label>
                  <div className="form-group col-md-6">
                    <input
                      type="text"
                      className="form-control"
                      name="streetAddress"
                      id="streetAddress"
                      placeholder="Street Address"
                      required
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <input
                      type="text"
                      className="form-control"
                      name="address2"
                      id="address2"
                      placeholder="Address line 2"
                    />
                  </div>
                </div>
                <div className="row mt-3 mt-md-0">
                  <div className="col-md-4 form-group ">
                    <input
                      type="text"
                      className="form-control"
                      name="city"
                      id="city"
                      placeholder="City"
                      required
                    />
                  </div>
                  <div className="col-md-4 form-group mt-md-0">
                    <input
                      type="text"
                      className="form-control"
                      name="state"
                      id="state"
                      placeholder="State / Province"
                      required
                    />
                  </div>
                  <div className="form-group col-md-4">
                    <input
                      type="text"
                      className="form-control"
                      name="postalCode"
                      id="postalCode"
                      placeholder="Postal / Zip Code"
                    />
                  </div>
                </div>
                {/* END ADDRESS */}

                {/* ARRIVAL DATE-TIME */}
                <div className="row mt-3">
                  <div className="col-md-6 form-group mt-md-0">
                    <label htmlFor="arrivalDate">ARRIVAL - DATE </label>
                    <input
                      type="date"
                      className="form-control"
                      name="arrivalDate"
                      onChange={(e) => setArrivalDateBooking(e.target.value)}
                      min={new Date().toISOString().split("T")[0]}
                      id="arrivalDate"
                      required
                    />
                  </div>
                  <div className="col-md-4 form-group mt-md-0">
                    <label htmlFor="arrivalTime">TIME </label>
                    <input
                      type="time"
                      className="form-control"
                      name="arrivalTime"
                      id="arrivalTime"
                      required
                    />
                  </div>
                </div>
                {/* END ARRIVAL DATE-TIME */}

                {/* DEPARTURE DATE-TIME */}
                <div className="row mt-3">
                  <div className="col-md-6 form-group mt-md-0">
                    <label htmlFor="departureDate">DEPARTURE - DATE </label>
                    <input
                      type="date"
                      min={
                        arrivalDateBooking
                          ? arrivalDateBooking
                          : new Date().toISOString().split("T")[0]
                      }
                      className="form-control"
                      name="departureDate"
                      id="departureDate"
                      required
                    />
                  </div>
                  <div className="col-md-4 form-group mt-md-0">
                    <label htmlFor="departureTime">TIME</label>
                    <input
                      type="time"
                      className="form-control"
                      name="departureTime"
                      id="departureTime"
                      required
                    />
                  </div>
                </div>
                {/* END DEPARTURE DATE-TIME */}

                {/* EFFECTIFS */}
                <div className="row mt-3">
                  <div className="col-md-6 form-group mt-md-0">
                    <label htmlFor="adults">NUMBER OF ADULTS</label>
                    <input
                      type="number"
                      className="form-control"
                      name="adults"
                      id="adults"
                      placeholder="example: 5"
                      min={1}
                      required
                    />
                  </div>
                  <div className="col-md-6 form-group mt-3 mt-md-0">
                    <label htmlFor="kids">
                      NUMBER OF KIDS (if there are any)
                    </label>
                    <input
                      type="number"
                      min={1}
                      className="form-control"
                      placeholder="example: 7"
                      name="kids"
                      id="kids"
                      required
                    />
                  </div>
                </div>
                {/* END EFFECTIFS */}

                {/* SPECIAL REQUEST */}
                <div className="form-group mt-3">
                  <label>Do you have any special request ?</label>
                  <textarea
                    className="form-control"
                    name="message"
                    rows="5"
                    placeholder="Type here..."
                    required
                  ></textarea>
                </div>
                {/* END SPECIAL REQUEST */}

                <div className="text-center">
                  {loadingSend ? (
                    <button className="btn btn-primary" type="button" disabled>
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      <span style={{ marginLeft: "7px" }}>Loading...</span>
                    </button>
                  ) : (
                    <button type="submit">SUBMIT</button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

import React from "react";

export default function Transport() {
  return (
    <React.Fragment>
      {/* TRANSPORT */}
      <section className="cultural activities-details">
        <div className="container mb-5">
          <div className="activities-details-title" data-aos="zoom-out">
            <p>TRANSPORT</p>
            <div className="mt-3">
              <span>
                Arrive stress-free! Arrive in style and convenience with our
                reliable airport transport service pick-up or drop-off, ensuring
                hassle-free travel to and from the Guesthouse. Our dedicated
                team will ensure a smooth and comfortable journey, allowing you
                to focus on enjoying your stay to the fullest. Book now for a
                hassle-free start to your journey.
              </span>
            </div>
          </div>
        </div>
      </section>
      {/* Fin TRANSPORT */}
    </React.Fragment>
  );
}

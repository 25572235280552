import React from "react";
import "../../../../assets/css/activitiesDetails.css";
import AllActivities from "./AllActivities";
import Navigator from "./Navigator";

export default function ActivitiesDetails() {
  return (
    <React.Fragment>
      <section
        id="activities-archives"
        className="activities-archives"
      ></section>
      {/* NAVIGATOR */}
      <Navigator />
      {/* END NAVIGATOR */}

      {/* ALL SECTIONS */}
      <AllActivities />
      {/* END ALL SECTIONS */}
    </React.Fragment>
  );
}

import React, { useContext, useState } from "react";
import "../../../assets/css/megaMenu.css";
import Logo from "./../../../assets/images/logo/logo-lovasoa.png";
import { NavLink } from "react-router-dom";
import ContextService from "../../../context/ContextService";
// import imageNav1 from "../../../assets/images1.jpg";
// import imageNav2 from "../../../assets/images2.jpg";
// import imageNav3 from "../../../assets/images3.jpg";
// import imageNav4 from "../../../assets/images4.jpg";
// import imageNav5 from "../../../assets/images5.jpg";
// import imageNav6 from "../../../assets/images6.jpg";
// import imageNav7 from "../../../assets/images7.jpg";
// import imageNav8 from "../../../assets/images8.jpg";

function HeaderCore() {
  // CONTEXT SERVICE ------------------------------
  const { resetWindowY } = useContext(ContextService);

  // state: permet d'ouvrir/fermer la navigation sur mobile
  const [openNavMobile, setOpenNavMobile] = useState(false);

  // func: permet de gerer l'ouverture de Nav-Mobile
  const handleOpenNav = () => {
    setOpenNavMobile(true);
  };

  // func: permet de gerer la fermeture de Nav-Mobile
  const handleCloseNav = () => {
    resetWindowY();
    setOpenNavMobile(false);
  };

  return (
    <header
      id="header"
      className="fixed-top d-flex align-items-center header-transparent"
    >
      <div className="container-fluid container-xl d-flex align-items-center justify-content-between">
        <div className="logo me-auto">
          <img className="logo-lovaso" src={Logo} alt="logo" />
        </div>

        {/* DESKTOP NAVIGATION */}
        <nav id="navbar" className="navbar order-last order-lg-0">
          <ul>
            <li>
              <NavLink
                onClick={resetWindowY}
                className="nav-link"
                to={"/index"}
              >
                HOME
              </NavLink>
            </li>
            <li>
              <NavLink
                onClick={resetWindowY}
                className="nav-link"
                to="/activities"
              >
                ACTIVITIES
              </NavLink>
            </li>

            <li>
              <NavLink
                onClick={resetWindowY}
                to="/services"
                className="nav-link"
              >
                SERVICES
              </NavLink>
            </li>

            <li>
              <NavLink
                onClick={resetWindowY}
                to="/students"
                className="nav-link"
              >
                FOR STUDENTS
              </NavLink>
            </li>
          </ul>
          {!openNavMobile && (
            <i
              onClick={handleOpenNav}
              className="bi bi-list mobile-nav-toggle"
            ></i>
          )}
        </nav>
        {/* END DESKTOP NAVIGATION */}

        {/* MOBILE NAVIGATION */}
        {openNavMobile && (
          <nav
            id="navbar-mobile"
            className="navbar order-last order-lg-0 navbar-mobile"
          >
            <i
              onClick={handleCloseNav}
              className="bi bi-x mobile-nav-toggle"
            ></i>
            <ul>
              <li>
                <NavLink
                  onClick={handleCloseNav}
                  className="nav-link"
                  to="/index"
                >
                  HOME
                </NavLink>
              </li>

              <li>
                <NavLink
                  onClick={handleCloseNav}
                  to="/activities"
                  className="nav-link"
                >
                  ACTIVITIES
                </NavLink>
              </li>

              <li>
                <NavLink
                  onClick={handleCloseNav}
                  to="/services"
                  className="nav-link"
                >
                  SERVICES
                </NavLink>
              </li>

              <li>
                <NavLink
                  onClick={handleCloseNav}
                  to="/students"
                  className="nav-link"
                >
                  FOR STUDENTS
                </NavLink>
              </li>
            </ul>
          </nav>
        )}
        {/* END MOBILE NAVIGATION */}

        <NavLink
          to="/booking"
          onClick={resetWindowY}
          className="book-a-table-btn"
        >
          FOR VISITOR
        </NavLink>
      </div>
    </header>
  );
}

export default HeaderCore;

import React from "react";
import "../../../../assets/css/servicesDetails.css";
import NavigatorServices from "./NavigatorServices";
import AllServices from "./AllServices";

export default function ServicesDetail() {
  return (
    <React.Fragment>
      <section className="activities-archives"></section>
      <NavigatorServices />

      <AllServices />
    </React.Fragment>
  );
}

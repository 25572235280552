import React from "react";

export default function Museum() {
  return (
    <React.Fragment>
      <section className="d-flex align-items-center py-5">
        <div className="container">
          <div className="activities-details-title">
            <h2>activity</h2>
            <p>MUSEUM</p>
          </div>

          <div className="row content" data-aos="fade-up">
            <div className="col-lg-6">
              <p>
                Discover captivating exhibitions and awe-inspiring artefacts
                that chronicle the history of the founding of Antsirabe, the
                Lutheran church, and the Norwegian missionaries.
              </p>
              <h3>The L4C museum</h3>
              <p>
                Is the only museum in Antsirabe that highlights the different
                aspects of the Norwegian Malagasy-cooperation. It is equipped
                with several unique objects collected from several mission
                stations. The museum also has a space for both permanent and
                temporary exhibitions. The uniqueness of its collections offers
                visitors the opportunity to immerse themselves in the
                fascinating history of the Malagasy Lutheran church, the town of
                Antsirabe and the works of the Norwegian missionaries which are
                strongly linked to the development of Madagascar and its
                population.
              </p>
            </div>
            <div className="col-lg-6 pt-4 pt-lg-0">
              <p>
                The L4C museum is accessible to the general public, both
                residents and foreigners. It stands as a testament to the power
                of Norwegian-Malagasy cooperation in shaping the world we
                inhabit, leaving an indelible mark on all who pass through its
                doors.
              </p>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

import React, { useContext, useEffect } from "react";
import { Outlet } from "react-router-dom";
import "../../../assets/css/forStudents.css";
import "../../../assets/css/style.css";
import ContextService from "../../../context/ContextService";
import FooterCore from "../footer/FooterCore";
import HeaderCore from "../header/HeaderCore";
import FacebookLink from "./FacebookLink";

export default function LayoutCore() {
  const { windowY } = useContext(ContextService);

  useEffect(() => {
    const headerApp = document.querySelector("#header");
    headerApp.style.marginTop = "-3.3rem";
  }, []);
  return (
    <React.Fragment>
      <HeaderCore />
      <Outlet />
      <FacebookLink />
      <FooterCore />
    </React.Fragment>
  );
}

import React, { useContext } from "react";
import students from "../../../../assets/images/home/for_students.webp";
import { Link } from "react-router-dom";
import ContextService from "../../../../context/ContextService";

export default function IndexForStudents() {
  // CONTEXT SERVICE ------------------------------
  const { resetWindowY } = useContext(ContextService);

  return (
    <React.Fragment>
      <section
        id="cta"
        className="cta specials"
        style={{
          background: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.5)), url(${students}) fixed center center`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="container text-center">
          <div className="section-title animate__animated animate__fadeInUp">
            <h2>
              for International <span>Students</span>
            </h2>
            <p className="text-center animate__animated animate__fadeInUp">
              Are you an international student eager to immerse yourself in a
              vibrant cultural experience while gaining valuable practical
              skills? Look no further than Antsirabe, Madagascar's charming
              highland city! Gain hands-on experience through internships,
              volunteer opportunities, and research projects tailored to your
              interests.
            </p>
          </div>
          <Link
            to="/students"
            onClick={resetWindowY}
            className="more-details mx-auto animate__animated animate__fadeInUp"
          >
            More details
          </Link>
        </div>
      </section>
    </React.Fragment>
  );
}

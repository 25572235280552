import React, { useEffect } from "react";
import Image from "../../../../assets/images/services/Team-lovasoa-3.jpeg";
import "../../../../assets/css/teamEmployes.css";
//import { NavLink } from "react-router-dom";

import Employe1 from "../../../../assets/images/chefs/chefs-1.jpg";
import Employe2 from "../../../../assets/images/chefs/chefs-2.jpg";
import Employe3 from "../../../../assets/images/chefs/chefs-3.jpg";

export default function Founding() {
  useEffect(() => {
    const topbar = document.querySelector("#topbar");
    const headerApp = document.querySelector("#header");
    window.addEventListener("scroll", function () {
      if (topbar && headerApp) {
        if (window.scrollY > 70) {
          topbar.classList.remove("fixed-top");
          headerApp.classList.remove("header-transparent");
          headerApp.style.marginTop = "-3.3rem";
        } else {
          headerApp.style.marginTop = 0;
          headerApp.classList.add("header-transparent");
          topbar.classList.add("fixed-top");
        }
      }
    });
  }, []);

  return (
    <React.Fragment>
      <section id="details" className="activities-archives">
        {/* breadcrumb-section */}
        <div className="breadcrumb-section breadcrumb-bg">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 offset-lg-2 text-center">
                <div className="breadcrumb-text">
                  <p>Organization</p>
                  <h1>Employees</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* end breadcrumb section */}
      </section>

      <section className="section-about">
        <div className="container">
          <div className="row">
            <div className="col-md-12 section-t8 position-relative">
              <div className="row">
                <div className="col-md-6 col-lg-5">
                  <img src={Image} alt="" className="img-fluid rounded-4" />
                </div>
                <div className="col-lg-2  d-none d-lg-block position-relative">
                  <div className="title-vertical d-flex justify-content-start"></div>
                </div>
                <div className="col-md-6 col-lg-5 section-md-t3 pb-5">
                  <div className="title-box-d">
                    <p className="title-d">
                      <span className="color-d">Our employees</span>
                    </p>
                  </div>
                  <p className="color-text-a">
                    We uphold the vision, values and missions by their
                    commitment and diligence. The permanent workers (Malagasy
                    and Norwegians) are about 60 and are supported by skilled
                    temporary staffs and volunteers during peak seasons.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <section id="team" class="team">
            <div class="container" data-aos="fade-up">
              <div class="section-title">
                <h2>Organigramme</h2>
              </div>

              <div class="row">
                <div class="col-lg-6" data-aos="zoom-in" data-aos-delay="100">
                  <div class="member d-flex align-items-start">
                    <div class="pic">
                      <img src={Employe1} class="img-fluid" alt="" />
                    </div>
                    <div class="member-info">
                      <h4>Walter White</h4>
                      <span>Chief Executive Officer</span>
                      <p>
                        Explicabo voluptatem mollitia et repellat qui dolorum
                        quasi
                      </p>
                    </div>
                  </div>
                </div>

                <div
                  class="col-lg-6 mt-4 mt-lg-0"
                  data-aos="zoom-in"
                  data-aos-delay="200"
                >
                  <div class="member d-flex align-items-start">
                    <div class="pic">
                      <img src={Employe2} class="img-fluid" alt="" />
                    </div>
                    <div class="member-info">
                      <h4>Sarah Jhonson</h4>
                      <span>Product Manager</span>
                      <p>
                        Aut maiores voluptates amet et quis praesentium qui
                        senda para
                      </p>
                    </div>
                  </div>
                </div>

                <div
                  class="col-lg-6 mt-4"
                  data-aos="zoom-in"
                  data-aos-delay="300"
                >
                  <div class="member d-flex align-items-start">
                    <div class="pic">
                      <img src={Employe3} class="img-fluid" alt="" />
                    </div>
                    <div class="member-info">
                      <h4>William Anderson</h4>
                      <span>CTO</span>
                      <p>
                        Quisquam facilis cum velit laborum corrupti fuga rerum
                        quia
                      </p>
                    </div>
                  </div>
                </div>

                <div
                  class="col-lg-6 mt-4"
                  data-aos="zoom-in"
                  data-aos-delay="400"
                >
                  <div class="member d-flex align-items-start">
                    <div class="pic">
                      <img src={Employe1} class="img-fluid" alt="" />
                    </div>
                    <div class="member-info">
                      <h4>Amanda Jepson</h4>
                      <span>Accountant</span>
                      <p>
                        Dolorum tempora officiis odit laborum officiis et et
                        accusamus
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </section>
    </React.Fragment>
  );
}

import React, { useContext } from "react";
import ContextService from "../../../../context/ContextService";

export default function Navigator() {
  const {
    swapActivityToLibrary,
    swapActivityToArchive,
    swapActivityToMuseum,
    swapActivityToMusicSchool,
    swapActivityToStudio,
    swapActivityToCultural,
    swapActivityToLanguageSchool,
    swapActivityToExchange,
  } = useContext(ContextService);

  return (
    <React.Fragment>
      <div
        id="activity-navigator"
        className="section navigator"
        style={{
          width: "90%",
          margin: "auto",
          justifyContent: "center",
          alignItems: "center",
          justifySelf: "center",
          marginBottom: "3rem",
        }}
      >
        <ul
          className="list-group list-group-horizontal-sm"
          style={{
            justifyContent: "center",
          }}
        >
          <li>
            <div
              id="activity-library"
              className="icon-box "
              data-aos="zoom-in"
              data-aos-delay="50"
              onClick={swapActivityToLibrary}
            >
              <i className="bi bi-book-half"></i>
              <a>LIBRARY</a>
            </div>
          </li>

          <li>
            <div
              id="activity-archive"
              className="icon-box"
              data-aos="zoom-in"
              data-aos-delay="50"
              onClick={swapActivityToArchive}
            >
              <i className="bx bx-church"></i>
              <a>ARCHIVE</a>
            </div>
          </li>

          <li>
            <div
              id="activity-museum"
              className="icon-box "
              data-aos="zoom-in"
              data-aos-delay="50"
              onClick={swapActivityToMuseum}
            >
              <i className="bi bi-door-open-fill"></i>
              <a>MUSEUM</a>
            </div>
          </li>

          <li>
            <div
              id="activity-music-school"
              className="icon-box"
              data-aos="zoom-in"
              data-aos-delay="50"
              onClick={swapActivityToMusicSchool}
            >
              <i className="bi bi-music-note-list"></i>
              <a>MUSIC SCHOOL</a>
            </div>
          </li>
          <li>
            <div
              id="activity-studio"
              className="icon-box "
              data-aos="zoom-in"
              data-aos-delay="50"
              onClick={swapActivityToStudio}
            >
              <i className="bi bi-mic-fill"></i>
              <a>RECORDING STUDIO</a>
            </div>
          </li>
          <li>
            <div
              id="activity-cultural"
              className="icon-box"
              data-aos="zoom-in"
              data-aos-delay="50"
              onClick={swapActivityToCultural}
            >
              <i className="bi bi-palette-fill"></i>
              <a>CULTURAL</a>
            </div>
          </li>
          <li>
            <div
              id="activity-language-school"
              className="icon-box "
              data-aos="zoom-in"
              data-aos-delay="50"
              onClick={swapActivityToLanguageSchool}
            >
              <i className="bi bi-translate"></i>
              <a>LANGUAGE SCHOOL</a>
            </div>
          </li>
          <li>
            <div
              id="activity-exchange"
              className="icon-box "
              data-aos="zoom-in"
              data-aos-delay="50"
              onClick={swapActivityToExchange}
            >
              <i className="bi bi-chat-square-dots-fill"></i>
              <a>EXCHANGE</a>
            </div>
          </li>
        </ul>
      </div>
    </React.Fragment>
  );
}

import React from "react";

export default function CompetenceSection() {
  return (
    <React.Fragment>
      <section id="services" className="services bg-grey">
        <div className="container">
          <div className="section-title" data-aos="fade-up">
            <h2>Our Services</h2>
            <p>
              Welcome to Our Charming Guesthouse: Your Haven for Conferences and
              Comfort!
            </p>
          </div>

          <div className="row mb-4">
            <div className="col-md-6 col-lg-4 d-flex align-items-stretch my-4">
              <div className="icon-box" data-aos="fade-up" data-aos-delay="100">
                <div className="icon">
                  <i className="bi bi-calendar-event"></i>
                </div>
                <h4 className="title">
                  <a href="">Conferences & Meeting Rooms</a>
                </h4>
                <p className="description">
                  Elevate your business gatherings and events with our
                  well-equipped conference facilities and meeting rooms.
                </p>
              </div>
            </div>

            <div className="col-md-6 col-lg-4 d-flex align-items-stretch my-4">
              <div className="icon-box" data-aos="fade-up" data-aos-delay="300">
                <div className="icon">
                  <i className="bi bi-house"></i>
                </div>
                <h4 className="title">
                  <a href="">Accomodation</a>
                </h4>
                <p className="description">
                  Rest easy in our cozy and meticulously appointed guest rooms,
                  designed to provide the ultimate comfort and relaxation.
                </p>
              </div>
            </div>

            <div className="col-md-6 col-lg-4 d-flex align-items-stretch my-4">
              <div className="icon-box" data-aos="fade-up" data-aos-delay="400">
                <div className="icon">
                  <i className="bi bi-truck"></i>
                </div>
                <h4 className="title">
                  <a href="">Transport</a>
                </h4>
                <p className="description">
                  Arrive stress-free! Arrive in style and convenience with our
                  reliable airport transport service pick-up or drop-off,
                  ensuring hassle-free travel to and from the Guesthouse.
                </p>
              </div>
            </div>

            <div className="col-md-6 col-lg-4 d-flex align-items-stretch my-4">
              <div className="icon-box" data-aos="fade-up" data-aos-delay="100">
                <div className="icon">
                  <i className="bi bi-award-fill"></i>
                </div>
                <h4 className="title">
                  <a href="">Sport and Leisure</a>
                </h4>
                <p className="description">
                  Antsirabe is one of the most beautiful areas for sports and
                  cultural activities.
                </p>
              </div>
            </div>

            <div className="col-md-6 col-lg-4 d-flex align-items-stretch my-4">
              <div className="icon-box" data-aos="fade-up" data-aos-delay="200">
                <div className="icon">
                  <i className="bi bi-cup-hot-fill"></i>
                </div>
                <h4 className="title">
                  <a href="">Restaurant</a>
                </h4>
                <p className="description">
                  Savor the flavors of Madagascar and beyond at our onsite
                  restaurant, where culinary delights await your discerning
                  palate.
                </p>
              </div>
            </div>

            <div className="col-md-6 col-lg-4 d-flex align-items-stretch my-4">
              <div className="icon-box" data-aos="fade-up" data-aos-delay="300">
                <div className="icon">
                  <i className="bi bi-flag"></i>
                </div>
                <h4 className="title">
                  <a href="">Malagasy cultural events</a>
                </h4>
                <p className="description">
                  Experience the vibrant tapestry of Malagasy culture come alive
                  in our exhilarating cultural events.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

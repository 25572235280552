import React from "react";

export default function MusicSchool() {
  return (
    <React.Fragment>
      <section
        id="music-school"
        className="archive activities-details"
        style={{
          bottom: "60px",
          top: "60px",
          left: 0,
          right: 0,
        }}
      >
        <div className="container">
          <div className="activities-details-title" data-aos="zoom-out">
            <h2>activity</h2>
            <p>MUSIC SCHOOL</p>
            <div className="mt-3">
              <span>
                Ignite your passion for melody and rhythm in our esteemed music
                school where passion meets practice, and where dreams take
                flight. As we continue to inspire and empower the musicians of
                tomorrow, we look forward to witnessing the transformative
                impact of music on the lives of our students.
              </span>
            </div>
          </div>

          <div className="row icon-boxes">
            <div className="col-lg-4 col-md-6">
              <div className="icon-box" data-aos="zoom-in-left">
                <h4 className="title">The Music School</h4>
                <p className="description">
                  is dedicated to nurturing the next generation of musical
                  talent. Our doors are open to students of all ages and
                  proficiency levels, offering a comprehensive curriculum
                  designed to foster growth and excellence in various musical
                  disciplines. We offer classes in piano, guitar, bass guitar,
                  saxophone, drums and “valiha”.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mt-5 mt-md-0">
              <div
                className="icon-box"
                data-aos="zoom-in-left"
                data-aos-delay="100"
              >
                <h4 className="title">Our curriculum</h4>
                <p className="description">
                  encompasses a diverse range of musical disciplines, ensuring a
                  well-rounded education for all students. From instrumental
                  performance to music theory and composition, our courses are
                  designed to cultivate both technical proficiency and creative
                  expression. Through hands-on instruction, students develop a
                  deep understanding of musical concepts and gain the skills
                  necessary to excel in their chosen discipline.
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 mt-5 mt-lg-0 ">
              <div
                className="icon-box"
                data-aos="zoom-in-left"
                data-aos-delay="200"
              >
                <h4 className="title">Beyond individual instruction</h4>
                <p className="description">
                  , our music school fosters a sense of community and
                  collaboration among students. Through end-of-term concerts,
                  workshops, and masterclasses, students have the opportunity to
                  connect with their peers, share their passion for music, and
                  develop valuable teamwork skills. Moreover, our music
                  education centre is committed to accessibility and
                  inclusivity, welcoming students from all backgrounds and
                  abilities.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export const initialState = {
  // Status
  sendingStatus: false,

  // Employees
  employees: [],
  
  // URLS
  baseUrl: "http://127.0.0.1:8000/api/",
  // imageUrl: "http://127.0.0.1:8000/storage",
}

export const reducerService = ({state, action}) => {
  switch (action.type) {
    case "GET_EMPLOYEES":
      return {
        ...state,
        employees: action.payload.employees
      }
    
    case "STATUS_OK":
      return {
        ...state,
        sendingStatus: true,
      }

    case "STATUS_BAD":
      return {
        ...state,
        sendingStatus: false,
      }
  
    default:
      return {...state};
  }
}

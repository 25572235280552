import React, { useContext } from "react";
import Library from "./Library";
import Archive from "./Archive";
import Museum from "./Museum";
import MusicSchool from "./MusicSchool";
import Studio from "./Studio";
import Cultural from "./Cultural";
import LanguageSchool from "./LanguageSchool";
import Exchange from "./Exchange";
import ContextService from "../../../../context/ContextService";

export default function AllActivities() {
  const { activity } = useContext(ContextService);

  switch (activity) {
    case "LIBRARY":
      return <Library />;
    case "ARCHIVE":
      return <Archive />;
    case "MUSEUM":
      return <Museum />;
    case "MUSIC_SCHOOL":
      return <MusicSchool />;
    case "STUDIO":
      return <Studio />;
    case "CULTURAL":
      return <Cultural />;
    case "LANGUAGE_SCHOOL":
      return <LanguageSchool />;

    case "EXCHANGE":
      return <Exchange />;

    default:
      return null;
  }
}

import React from "react";
import IndexActivities from "./activities/IndexActivities";
import IndexForStudents from "./for_students/IndexForStudents";
import CompetenceSection from "./sections/CompetenceSection";
import WelcomeSection from "./sections/WelcomeSection";

function HomePage() {
  return (
    <React.Fragment>
      <WelcomeSection />
      <IndexActivities />
      <CompetenceSection />
      <IndexForStudents />
    </React.Fragment>
  );
}

export default HomePage;

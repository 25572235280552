import React, { useContext } from "react";
import ContextService from "../../../../context/ContextService";

export default function NavigatorServices() {
  const {
    swapServiceToConference,
    swapServiceToAccomodation,
    swapServiceToTransport,
    swapServiceToSport,
    swapServiceToRestaurant,
    swapServiceToCultural,
  } = useContext(ContextService);

  return (
    <React.Fragment>
      <div
        className="section navigator"
        style={{
          width: "90%",
          margin: "auto",
          justifyContent: "center",
          alignItems: "center",
          justifySelf: "center",
          marginBottom: "3rem",
        }}
      >
        <ul
          className="list-group list-group-horizontal-sm"
          style={{
            justifyContent: "center",
          }}
        >
          <li>
            <div
              id="service-conference"
              className="icon-box "
              data-aos="zoom-in"
              data-aos-delay="50"
              onClick={swapServiceToConference}
            >
              <i className="bi bi-calendar-event"></i>
              <a>CONFERENCES</a>
            </div>
          </li>

          <li>
            <div
              id="service-accomodation"
              className="icon-box"
              data-aos="zoom-in"
              data-aos-delay="50"
              onClick={swapServiceToAccomodation}
            >
              <i className="bi bi-house"></i>
              <a>ACCOMODATION</a>
            </div>
          </li>

          <li>
            <div
              id="service-transport"
              className="icon-box"
              data-aos="zoom-in"
              data-aos-delay="50"
              onClick={swapServiceToTransport}
            >
              <i className="bi bi-truck"></i>
              <a>TRANSPORT</a>
            </div>
          </li>

          <li>
            <div
              id="service-sport-leisures"
              className="icon-box "
              data-aos="zoom-in"
              data-aos-delay="50"
              onClick={swapServiceToSport}
            >
              <i className="bi bi-award"></i>
              <a>SPORT & LEISURES</a>
            </div>
          </li>

          <li>
            <div
              id="service-restaurant"
              className="icon-box "
              data-aos="zoom-in"
              data-aos-delay="50"
              onClick={swapServiceToRestaurant}
            >
              <i className="bi bi-cup-hot"></i>
              <a>RESTAURANT</a>
            </div>
          </li>

          <li>
            <div
              id="service-cultural"
              className="icon-box"
              data-aos="zoom-in"
              data-aos-delay="50"
              onClick={swapServiceToCultural}
            >
              <i className="bi bi-flag"></i>
              <a>CULTURAL EVENTS</a>
            </div>
          </li>
        </ul>
      </div>
    </React.Fragment>
  );
}

import React from "react";
import studio from "../../../../assets/images/activities/studio.jpg";

export default function Studio() {
  return (
    <React.Fragment>
      <section id="music-production" className="archive">
        <div className="container" data-aos="fade-up">
          <div className="activities-details-title">
            <h2>activity</h2>
            <p>
              MUSIC PRODUCTION <br /> & RECORDING STUDIO
            </p>
            <div className="mt-3">
              <span>
                Unleash your creative genius and make musical magic in our
                state-of-the-art production and recording studio. From
                composition to mixing, our top-tier facilities and seasoned
                engineers provide the perfect canvas for your sonic aspirations.
              </span>
            </div>
          </div>
          <div className="row no-gutters">
            <div
              className="col-lg-12 mx-auto my-5"
              style={{
                background: `url(${studio}) center center no-repeat`,
                backgroundSize: "cover",
                height: "78vh",
              }}
            ></div>
            <div className="icon-boxes d-flex flex-column justify-content-center">
              <div className="row">
                <div className="col-md-6">
                  <div
                    className="icon-box"
                    data-aos="fade-up"
                    data-aos-delay="100"
                  >
                    <i className="bx bx-headphone"></i>
                    <h3>L4C sound recording studio</h3>
                    <p>
                      serves as a dynamic space where the symphony of creativity
                      meets technical prowess. Musicians, sound artists, choirs,
                      singers, and individuals alike are welcomed into an
                      environment where their sonic visions can come to life. At
                      our recording studio, the possibilities are as boundless
                      as the imagination. Equipped with state-of-the-art
                      technology and manned by a skilled professional, we offer
                      a platform for the realization of musical dreams and audio
                      endeavours. Whether it is laying down tracks for an album,
                      capturing the essence of a live performance, or refining
                      audio for various projects, our studio stands ready to
                      accommodate diverse needs.
                    </p>
                  </div>
                </div>
                <div className="col-md-6 mt-4 mt-md-0">
                  <div
                    className="icon-box"
                    data-aos="fade-up"
                    data-aos-delay="200"
                  >
                    <i className="bi bi-music-note-list"></i>
                    <h3>Beyond its technical capabilities</h3>
                    <p>
                      our studio serves as a nurturing ground for local talent
                      development. By providing access to professional-grade
                      equipment and expertise, we empower artists to explore
                      their craft and hone their skills. Moreover, our studio
                      fosters collaboration and community engagement. It serves
                      as a meeting point where artists can come together, share
                      ideas, and collaborate on projects that transcend
                      individual boundaries. Through these interactions, new
                      connections are forged, and creative synergies are
                      unleashed, enriching the cultural tapestry of our local
                      arts scene.
                    </p>
                    <p>
                      In essence, our sound recording studio is a catalyst for
                      artistic expression and a beacon of creativity in our
                      community. As we continue to nurture talent and facilitate
                      sonic innovation, we look forward to witnessing the
                      transformative power of music and audio unfold within our
                      walls.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

import React from "react";

export default function Accomodation() {
  return (
    <React.Fragment>
      {/* ACCOMODATION */}
      <section className="cultural activities-details">
        <div className="container mb-5">
          <div className="activities-details-title" data-aos="zoom-out">
            <p>ACCOMODATION</p>
            <div className="mt-3">
              <span>
                Rest easy in our cozy and meticulously appointed guest rooms,
                designed to provide the ultimate comfort and relaxation. Whether
                you are traveling solo, with family, or as part of a group, our
                spacious accommodations offer a tranquil retreat amidst the
                beauty of Lovasoa.
              </span>
            </div>
          </div>

          <div className="container">
            <ul className="cultural-list accordion" data-aos="fade-up">
              <li>
                <a
                  data-bs-toggle="collapse"
                  className="collapsed"
                  data-bs-target="#cultural1"
                >
                  <h4>The chalet</h4>
                  <i className="bx bx-chevron-down icon-show"></i>
                  <i className="bx bx-x icon-close"></i>
                </a>
                <div
                  id="cultural1"
                  className="collapse"
                  data-bs-parent=".cultural-list"
                >
                  <p>
                    Lovasoa Chalet is a full hotel standard facility with 17
                    rooms: 4 single rooms, 7 double rooms, 3 twin rooms and 3
                    triple rooms. Each of our stunning guest rooms has been
                    thoughtfully designed with a sumptuous range of accessories
                    highlighting the heritage of the past for your comfort. All
                    our rooms have private bathroom and are well equipped,
                    including high-speed internet access, safe box, hot water
                    and a parking. It offers spectacular views of the thermal
                    spa of Andranomafana, the Hotel des Thermes and its
                    beautiful gardens. We also offer rooms for people with
                    reduced mobility. The chalet has a ground floor, a small
                    common living room with access to the veranda equipped with
                    relaxing furniture located on the second floor and a common
                    living room with a flat screen on the third floor.
                  </p>
                </div>
              </li>
              <li>
                <a
                  data-bs-toggle="collapse"
                  data-bs-target="#cultural3"
                  className="collapsed"
                >
                  <h4>Our suites</h4>
                  <i className="bx bx-chevron-down icon-show"></i>
                  <i className="bx bx-x icon-close"></i>
                </a>
                <div
                  id="cultural3"
                  className="collapse"
                  data-bs-parent=".cultural-list"
                >
                  <p>
                    L4C has 13 suites overlooking the landscaped courtyard of
                    the property. Admire the bustling views of our beautiful
                    gardens or the city from the comfort of a private and
                    spacious terrace. They are beautifully designed and have 2
                    bedrooms, equipped with a kitchenette, a dining area and a
                    lounge area. We have 2 types of apartment suites: the small
                    apartment can host up to three people and the big apartment
                    from 4 to 6 people.
                  </p>
                </div>
              </li>

              <li>
                <a
                  data-bs-toggle="collapse"
                  data-bs-target="#cultural4"
                  className="collapsed"
                >
                  <h4>Our dormitory rooms</h4>
                  <i className="bx bx-chevron-down icon-show"></i>
                  <i className="bx bx-x icon-close"></i>
                </a>
                <div
                  id="cultural4"
                  className="collapse"
                  data-bs-parent=".cultural-list"
                >
                  <p>
                    The rooms in our cozy dorms are ideal for short stays and
                    for those on a limited budget. You can choose to stay in a
                    private double room with shared shower rooms and toilets, or
                    in a dormitory room with other people. The dormitory has a
                    shared, on-demand kitchen and a lounge with a TV where
                    guests can relax and meet other guests. Men and women’s
                    bathroom are separated. The dorm is also great if you are
                    traveling with a group of friends on a budget.
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>
      {/* Fin ACCOMODATION */}
    </React.Fragment>
  );
}

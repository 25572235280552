import React from "react";
import ImageActivities from "./../../../../assets/images/home/Lovasoa-exchange.jpeg";
import welcomeImage from "./../../../../assets/images/home/English-departement-2.jpeg";

export default function IndexActivities() {
  return (
    <React.Fragment>
      <section id="activities" className="activities">
        <div className="container py-5" data-aos="fade-up">
          <div className="row gx-0 justify-content-center align-items-start">
            <div className="col-12 col-lg-6">
              <div className="content p-5">
                <h2
                  style={{
                    fontWeight: 700,
                    lineHeight: "2.4rem",
                    letterSpacing: "4px",
                  }}
                >
                  Our activities
                </h2>
                <p>
                  Unveil the Rich Tapestry of Culture at Our Cultural
                  Department! Welcome to our vibrant Cultural Department, where
                  the essence of our heritage and creativity intertwine to
                  inspire, educate, and delight!
                </p>
              </div>
              <div
                className="content content-image px-5 text-white"
                style={{
                  background: `url(${welcomeImage}) center center`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
              ></div>
            </div>

            {/* side --- */}
            <div className="col-12 col-lg-6">
              <div
                className="content content-image"
                style={{
                  background: `url(${ImageActivities}) center center`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
              ></div>

              {/* <div
                className="content px-5 text-white"
                style={{
                  backgroundColor: "#185944",
                }}
              >
                <p
                  style={{
                    fontWeight: 100,
                    lineHeight: "2.4rem",
                    letterSpacing: "2px",
                    textAlign: "center",
                  }}
                >
                  STUDENTS FROM DIVERSE UNIVERSITIES
                </p>
              </div> */}
            </div>
            {/* end side --- */}
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

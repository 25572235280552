import React from "react";
import welcomeImage from "../../../../assets/images/home/lovasoa_home.webp";
import Room from "../../../../assets/images/home/lovasoa_home_2.jpeg";
import Flm from "../../../../assets/images/home/Team-lovasoa-2.jpeg";
import Underline from "../../../../assets/images/home/path.png";
import { Link } from "react-scroll";

export default function WelcomeSection() {
  return (
    <React.Fragment>
      <section className="about bg-grey">
        <div className="container-fluid">
          <div className="row gx-0 ">
            <div className="col-lg-6 p-5 d-flex flex-column justify-content-center align-items-center">
              <div
                className="content"
                style={{
                  marginBottom: "-1rem",
                }}
              >
                <h1 className="animate__animated animate__fadeInDown">
                  Welcome To
                </h1>
                <img
                  className="animate__animated animate__fadeInDown"
                  src={Underline}
                  alt="undrline"
                  style={{
                    marginTop: "-2.5rem",
                    rotate: "4deg",
                    height: "24px",
                  }}
                />
              </div>
              <p
                style={{
                  fontWeight: 300,
                  width: "100%",
                  fontSize: "24px",
                  lineHeight: "2.4rem",
                  letterSpacing: "7px",
                }}
                className="text-center animate__animated animate__fadeInUp"
              >
                Lovasoa Cross-cultural Competence Center
              </p>
              <Link
                to="activities"
                style={{
                  cursor: "pointer",
                  window: "70px",
                }}
                className="scroll-to-next animate__animated animate__fadeInUp"
              >
                <button className="cross-to-activities">
                  <i className="bi bi-arrow-down-short" aria-hidden="true"></i>
                </button>
                <span
                  style={{
                    letterSpacing: "3px",
                  }}
                >
                  Learn more
                </span>
              </Link>
            </div>

            <div className="col-lg-6">
              <div
                id="heroCarousel"
                className="carousel slide carousel-fade"
                data-bs-interval="3000"
                data-bs-ride="true"
              >
                <ul
                  className="carousel-indicators"
                  style={{
                    float: "left",
                  }}
                >
                  <li
                    className="active"
                    style={{
                      listStyle: "none",
                      backgroundColor: "#e57f19",
                      width: "15px",
                      height: "15px",
                      borderRadius: "50%",
                      cursor: "default",
                    }}
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide-to="0"
                    aria-current="true"
                    aria-label="Slide 1"
                  ></li>
                  <li
                    style={{
                      listStyle: "none",
                      backgroundColor: "#e57f19",
                      width: "15px",
                      height: "15px",
                      borderRadius: "50%",
                      cursor: "default",
                    }}
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide-to="1"
                    aria-label="Slide 2"
                  ></li>
                  <li
                    style={{
                      listStyle: "none",
                      backgroundColor: "#e57f19",
                      width: "15px",
                      height: "15px",
                      cursor: "default",
                      borderRadius: "50%",
                    }}
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide-to="2"
                    aria-label="Slide 3"
                  ></li>
                </ul>
                <div className="carousel-inner" role="listbox">
                  <div
                    className="carousel-item active"
                    style={{
                      width: "100%",
                      background: `url(${welcomeImage}) center center`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                    }}
                  ></div>
                  <div
                    className="carousel-item"
                    style={{
                      width: "100%",
                      background: `url(${Flm}) center center`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                    }}
                  ></div>
                  <div
                    className="carousel-item"
                    style={{
                      width: "100%",
                      background: `url(${Room}) center center`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                    }}
                  ></div>
                  <a
                    className="carousel-control-prev ml-3"
                    href="#heroCarousel"
                    role="button"
                    data-bs-slide="prev"
                  >
                    <span
                      className="carousel-control-prev-icon bi bi-chevron-left"
                      aria-hidden="true"
                    ></span>
                  </a>
                  <a
                    className="carousel-control-next mr-3"
                    href="#heroCarousel"
                    role="button"
                    data-bs-slide="next"
                  >
                    <span
                      className="carousel-control-next-icon bi bi-chevron-right"
                      aria-hidden="true"
                    ></span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

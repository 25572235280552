import React, { useEffect } from "react";
import Image from "../../../../assets/images/services/Team-lovasoa-3.jpeg";
import "../../../../assets/css/forStudentsDetails.css";
//import { NavLink } from "react-router-dom";

export default function Founding() {
  useEffect(() => {
    const topbar = document.querySelector("#topbar");
    const headerApp = document.querySelector("#header");
    window.addEventListener("scroll", function () {
      if (topbar && headerApp) {
        if (window.scrollY > 70) {
          topbar.classList.remove("fixed-top");
          headerApp.classList.remove("header-transparent");
          headerApp.style.marginTop = "-3.3rem";
        } else {
          headerApp.style.marginTop = 0;
          headerApp.classList.add("header-transparent");
          topbar.classList.add("fixed-top");
        }
      }
    });
  }, []);

  return (
    <React.Fragment>
      <section id="details" className="activities-archives">
        {/* breadcrumb-section */}
        <div className="breadcrumb-section breadcrumb-bg">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 offset-lg-2 text-center">
                <div className="breadcrumb-text">
                  <p>Organization</p>
                  <h1>Owners</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* end breadcrumb section */}
      </section>

      <section className="section-about">
        <div className="container">
          <div className="row">
            <div className="col-md-12 section-t8 position-relative">
              <div className="row">
                <div className="col-md-6 col-lg-5">
                  <img src={Image} alt="" className="img-fluid rounded-4" />
                </div>
                <div className="col-lg-1  d-none d-lg-block position-relative">
                  <div className="title-vertical d-flex justify-content-start"></div>
                </div>
                <div className="col-md-6 col-lg-5 section-md-t3 pb-5">
                  <div className="title-box-d">
                    <p className="title-d">
                      <span className="color-d">Our owners</span>
                    </p>
                  </div>
                  <p className="color-text-a">
                    We are national and foreign organizations that fulfill the
                    conditions of full membership and adhere to our statutes,
                    vision, mission and cores values. Among current partners are
                    the Municipality of Antsirabe I, the University of
                    Antananarivo, the Ecole Supérieure spécialisée du
                    Vakinankaratra.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

import React from "react";

export default function LanguageSchool() {
  return (
    <React.Fragment>
      <section
        id="language-school"
        className="archive"
        style={{
          bottom: "60px",
          top: "60px",
          left: 0,
          right: 0,
        }}
      >
        <div className="container" data-aos="fade-up">
          <div className="activities-details-title">
            <h2>activity</h2>
            <p>LANGUAGE SCHOOL</p>
          </div>
          <div className="row no-gutters">
            <div className="icon-boxes d-flex flex-column justify-content-center">
              <div className="row">
                <div className="col-md-6">
                  <div
                    className="icon-box"
                    data-aos="fade-up"
                    data-aos-delay="100"
                  >
                    <i className="bi bi-bookmark-check-fill"></i>
                    <h3>English Courses</h3>
                    <p>
                      The English Group Classes for Adults, aged from 15 years
                      old on, is offering six different levels aligning with the
                      CEFR or the Common European Framework of Reference from
                      the A1 level to the C1 level. Each level is running for 4
                      months offering an average of 72 hours of in-person
                      classes. The classes we offer aims at honing all the four
                      skills of listening, reading, writing and speaking. On top
                      of that, the learning environment of L4C provides a
                      platform that fosters global citizenship skills.
                    </p>
                    <p>
                      The English Group Classes for Kids is offering an
                      extra-curriculum program adapted to the age and grades of
                      the children aged 7 years old to 14 years old. The classes
                      run every Wednesday afternoons or Saturday mornings from
                      October to June every year. The classes aim at making the
                      kids get a feel of what English Language is and how it is
                      practised.
                    </p>
                    <p>
                      The English Private Classes are tailored courses for the
                      needs of each learner. The programs can get started from
                      an amount of 20 hours up to 72 hours.
                    </p>
                    <p>
                      The Specific Needs English Group Classes, is a tailored
                      program for a group of people from 5 to 10 people of
                      relatively the same language level, that wants to reach a
                      common language goal. The program aims at being flexible
                      to the group’s availability while staying goal-oriented.
                      This would be perfect for groups of co-workers, creatives,
                      friends, or family members.
                    </p>
                  </div>
                </div>
                <div className="col-md-6 mt-4 mt-md-0">
                  <div
                    className="icon-box"
                    data-aos="fade-up"
                    data-aos-delay="200"
                  >
                    <i className="bi bi-bookmark-heart-fill"></i>
                    <h3>Malagasy Courses</h3>
                    <p>
                      The Malagasy Group Classes offered at L4C consist of three
                      packages adapted to the length of stay of each group.
                      Should your group’s stay last 3 months or more in
                      Madagascar, the 60-hour package would be ideal for you to
                      be able to get into a deeper exchange with locals. Should
                      your group’s stay last from 2 or 3 months, the 20-hour
                      package would best suit you to engage in a confident daily
                      conversation with locals and specific work places. Should
                      your group’s stay last less than a month, the 10-hour
                      introduction course will be convenient to initiate simple
                      small talks with local co-workers and neighbours.
                    </p>
                    <p>
                      The Malagasy Private Classes aims at catering to specific
                      needs of individuals towards a set objective in each of
                      the four skills (listening, reading, writing, and
                      speaking). The hours will be adjusted according to the
                      availability of both learner and teacher. Practice time
                      within authentic settings will allow the learner to train
                      at the use of the Malagasy Language for effective live
                      performance. Should you consider a longer stay in
                      Madagascar, this will best suit you.
                    </p>
                    <p>
                      The Specific Needs Malagasy Group Classes, is a tailored
                      program for a group of people from 5 to 10 people of
                      relatively the same language level, that wants to reach a
                      common language goal. The program aims at being flexible
                      to the group’s availability while staying goal-oriented.
                      This would be perfect for groups of co-workers, creatives,
                      friends, or family members.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

import React from "react";
import library1 from "../../../../assets/images/activities/library-1.jpg";
import library2 from "../../../../assets/images/activities/library-2.jpg";

export default function Library() {
  return (
    <React.Fragment>
      <section id="library-details" className="activities-details">
        <div className="container">
          <div className="activities-details-title" data-aos="zoom-out">
            <h2>activity</h2>
            <p>LIBRARY</p>
          </div>

          <div className="row content" data-aos="fade-up">
            <div className="col-lg-6">
              <p>
                Immerse yothe boundless realm of knowledge with our collections.
              </p>
              <ul>
                <li>
                  <i className="bi bi-play-fill"></i> Urself in Organized
                  according to the Dewey Classification system,
                </li>
                <li>
                  <i className="bi bi-play-fill"></i> Our library ensures
                  efficient navigation and retrieval of materials,
                </li>
                <li>
                  <i className="bi bi-play-fill"></i> Facilitating seamless
                  access to information for our members.
                </li>
              </ul>
            </div>
            <div className="col-lg-6 pt-4 pt-lg-0">
              <p>
                L4C library comprises two distinct sections to cater to diverse
                interests and academic pursuits.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="features">
        <div className="container">
          <ul className="nav nav-tabs row d-flex">
            <li className="nav-item col-3" data-aos="zoom-in">
              <a
                className="nav-link active show"
                data-bs-toggle="tab"
                href="#tab-1"
              >
                <i className="bi bi-ui-checks-grid"></i>
                <p className="d-none d-lg-block">General Library</p>
              </a>
            </li>
            <li
              className="nav-item col-3"
              data-aos="zoom-in"
              data-aos-delay="100"
            >
              <a className="nav-link" data-bs-toggle="tab" href="#tab-2">
                <i className="bi bi-book-fill"></i>
                <p className="d-none d-lg-block">Historical Library</p>
              </a>
            </li>
          </ul>

          <div className="tab-content" data-aos="fade-up">
            <div className="tab-pane active show" id="tab-1">
              <div className="row">
                <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0">
                  <h3>The General Library</h3>
                  <p>
                    Features a comprehensive collection of cultural and
                    educational resources, encompassing topics such as
                    leadership, culture and customs, history of Antsirabe,
                    Madagascar and other countries, culinary arts, literature,
                    novels, dissertations, poetry, games, and an array of
                    literature tailored for women.
                  </p>
                  <p>
                    This section is designed to facilitate broad learning
                    experiences and intellectual exploration of our book
                    collections.
                  </p>
                </div>
                <div className="col-lg-6 order-1 order-lg-2 text-center mb-3">
                  <img
                    src={library1}
                    style={{
                      height: "400px",
                      rotate: "3deg",
                    }}
                    alt="library"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
            <div className="tab-pane" id="tab-2">
              <div className="row">
                <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0">
                  <h3>The Historical Library</h3>
                  <p>
                    will help you unearth the secrets of the past and unravel
                    the stories of yesteryears.
                  </p>
                  <p>
                    This specialized section is intricately linked to archival
                    resources, where books are exclusively accessible for
                    on-site reading. It is dedicated to preserving and
                    disseminating invaluable knowledge spanning theology and
                    religious education, various biographies including those of
                    missionaries, pastors and other church leaders, mission
                    journals, and narratives on missionary work.
                  </p>
                </div>
                <div className="col-lg-6 order-1 order-lg-2 text-center">
                  <img
                    src={library2}
                    style={{
                      height: "400px",
                      rotate: "3deg",
                    }}
                    alt="library"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

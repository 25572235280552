import React from "react";
import englishDepartement1 from "../../../../assets/images/activities/English-departement-1.jpeg";

export default function Exchange() {
  return (
    <React.Fragment>
      <section id="exchange" className="d-flex align-items-center py-5">
        <div className="container">
          <div className="activities-details-title">
            <h2>activity</h2>
            <p>L4C EXCHANGE</p>
          </div>

          <div className="row content" data-aos="fade-up">
            <div
              className="col-lg-6 mb-5"
              style={{
                background: `url(${englishDepartement1}) center center no-repeat`,
                backgroundSize: "cover",
                height: "75vh",
              }}
            ></div>
            <div className="col-lg-6">
              <p>
                Through L4C, students from diverse universities in Norway,
                Denmark, Poland etc. majoring in education, social work, and
                medicine have the opportunity to experience a glimpse of their
                future career in a completely different environment in a country
                with a different culture and a different language. While the
                students can face challenges during their stay (language
                barrier, culture shock, lack of resources compared to their home
                countries’) going from two (02) weeks to (06) months, they leave
                Madagascar having grown not only professionally but also
                personally: emotionally and psychologically. Sometimes they fall
                in love with L4C and Madagascar and come back as volunteers!
              </p>
              <p>
                During their practice, the students will be under the
                supervision of the study coordinator who regularly meets them
                throughout their practice for follow-up meetings, guidance, and
                advice. The study coordinator may organize seminars between the
                students within similar field of studies where they can learn
                from each others’ experience in their university and in their
                practice institutions. On the first week of their arrival, they
                even receive an introduction course to Malagasy language and
                culture before they dive into the world!
              </p>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

import React from "react";
import "../../../../assets/css/forStudentsDetails.css";
import forStudent2 from "../../../../assets/images/lovasoa/lovasoa3.jpg";
import forStudent from "../../../../assets/images/lovasoa/lovasoa4.jpg";
import forStudent1 from "../../../../assets/images/lovasoa/lovasoa9.jpg";

export default function ForStudentsDetails() {
  return (
    <React.Fragment>
      <section id="details" className="activities-archives"></section>

      <section className="intro-single">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-8">
              <div className="title-single-box">
                <p className="title-single">
                  For International <br />
                  Students
                </p>
              </div>
              <p>
                Are you an international student eager to immerse yourself in a
                vibrant cultural experience while gaining valuable practical
                skills? Look no further than Antsirabe, Madagascar's charming
                highland city! Gain hands-on experience through internships,
                volunteer opportunities, and research projects tailored to your
                interests.
              </p>

              <p>
                Engage with friendly locals and fellow international students in
                a welcoming community known for its warmth and hospitality.
                Experience Malagasy traditions, music, and cuisine, enriching
                your understanding of the world.
              </p>
              <p>
                Expand your horizons and embrace cross-cultural exchange with
                our esteemed Lovasoa Exchange program. Through immersive
                experiences via internships, fieldworks, surveys, field
                practice, study trips and meaningful interactions, embark on a
                journey of personal growth and mutual understanding as you
                connect with individuals from diverse backgrounds and
                perspectives.
              </p>
            </div>
            {/* <div className="col-md-12 col-lg-4">
              <nav
                aria-label="breadcrumb"
                className="breadcrumb-box d-flex justify-content-lg-end"
              >
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <NavLink to="/lovasoa/index">HOME</NavLink>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    FOR STUDENTS
                  </li>
                </ol>
              </nav>
            </div> */}
          </div>
        </div>
      </section>

      <section className="section-about">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 position-relative py-5">
              <div className="about-img-box">
                <img
                  src={forStudent}
                  alt=""
                  className="d-none d-lg-block img-fluid"
                />
              </div>
              <div className="sinse-box sinse-box-first">
                <p className="sinse-title">
                  Stories from Former
                  <span></span>
                  <br /> Exchange Students
                </p>
                <p className="text-white">
                  Hear firsthand accounts of transformation and discovery from
                  our former exchange students. From life-changing experiences
                  to unforgettable moments of connection, their stories serve as
                  inspiration for those embarking on their own journey of
                  exploration and cultural immersion.
                </p>
              </div>
            </div>

            <div className="col-md-12 section-t8 position-relative my-5 py-5">
              <div className="row">
                <div className="col-md-6 col-lg-5">
                  <img src={forStudent1} alt="" className="img-fluid" />
                </div>
                <div className="col-lg-2  d-none d-lg-block position-relative">
                  <div className="title-vertical d-flex justify-content-start"></div>
                </div>
                <div className="col-md-6 col-lg-5 section-md-t3 pb-5">
                  <div className="title-box-d">
                    <p className="title-d">
                      <span className="color-d">Traveling</span> To
                      <br /> L4C.
                    </p>
                  </div>
                  <p className="color-text-a">
                    Travelers from Europe will find themselves captivated by the
                    island's unique blend of French colonial heritage and
                    Malagasy culture, while those from America will be drawn to
                    the allure of exotic wildlife and pristine natural wonders.
                    Scandinavian explorers will marvel at the beauty of
                    Madagascar's diverse landscape, while Asian travelers will
                    be enchanted by the warmth and hospitality of the Malagasy
                    people. And for those arriving from Continental Africa, the
                    journey to L4C serves as a poignant reminder of the
                    continent's diverse tapestry of cultures and landscapes.
                  </p>
                  <p className="color-text-a">
                    Upon reaching Antsirabe, you will be greeted with a sense of
                    tranquility and charm. Known as Madagascar's "City of
                    Water," Antsirabe offers a delightful blend of colonial
                    architecture, bustling markets, and scenic landscapes. From
                    here, the journey to L4C unfolds amidst verdant hills and
                    picturesque valleys, providing a serene retreat from the
                    hustle and bustle of everyday life. Whether you're seeking
                    adventure, relaxation, or cultural immersion, L4C beckons
                    with its unparalleled beauty and charm. So pack your bags,
                    set your sights on Madagascar, and prepare for an
                    unforgettable journey to L4C, where every moment is infused
                    with magic and wonder.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-sm-12 position-relative mt-5">
              <div className="about-img-box">
                <img
                  src={forStudent2}
                  alt=""
                  className="d-none d-lg-block img-fluid"
                />
              </div>
              <div className="sinse-box sinse-box-second">
                <p className="sinse-title">
                  Safe and Affordable
                  <span></span>
                  <br /> Living
                </p>
                <p className="text-white">
                  Ready to embark on your Antsirabe adventure? An unforgettable
                  adventure to Lovasoa Antsirabe, where breathtaking landscapes
                  and warm hospitality awaits you. Nestled amidst picturesque
                  landscapes, Antsirabe boasts breathtaking views and outdoor
                  activities. Explore lush greenery, stunning lakes, and
                  volcanic formations while making memories to last a lifetime.
                  Explore bustling markets, cozy cafes, and vibrant nightlife
                  while feeling secure in a peaceful environment. Let the spirit
                  of exploration guide you as you discover the wonders of
                  Madagascar and create memories to last a lifetime.
                </p>
                <p className=" mt-3">
                  Contact us today to learn more about study abroad programs and
                  internships tailored to your interests!
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

import React from "react";

export default function Sport() {
  return (
    <React.Fragment>
      {/* SPORT AND LEISURES */}
      <section className="cultural activities-details">
        <div className="container mb-5">
          <div className="activities-details-title" data-aos="zoom-out">
            <p>SPORT AND LEISURES</p>
            <div className="mt-3">
              <span>
                Antsirabe is one of the most beautiful areas for sports and
                cultural activities. We then have fitness room, sports fields
                (tennis, volley, football and basketball), playing areas and
                green space for kids.
              </span>
            </div>
          </div>
          <section id="sport-leisure" className="sport-leisure">
            <div className="container">
              <div className="row">
                <div className="col-lg-3">
                  <ul className="nav nav-tabs flex-column">
                    <li className="nav-item">
                      <a
                        className="nav-link active show"
                        data-bs-toggle="tab"
                        href="#tab-1"
                      >
                        TENNIS COURT
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        data-bs-toggle="tab"
                        href="#tab-2"
                      >
                        FOOTBALL FIELD
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        data-bs-toggle="tab"
                        href="#tab-3"
                      >
                        VOLLEYBALL COURT
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        data-bs-toggle="tab"
                        href="#tab-4"
                      >
                        BASKET-BALL FIELD
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        data-bs-toggle="tab"
                        href="#tab-5"
                      >
                        GYM
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-9 mt-4 mt-lg-0">
                  <div className="tab-content">
                    <div className="tab-pane active show" id="tab-1">
                      <div className="row">
                        <div className="col-lg-8 details order-2 order-lg-1">
                          <h3>Our tennis court</h3>
                          <p className="fst-italic">
                            At L4C, our tennis court offers a premier sporting
                            experience. It's where enthusiasts refine their
                            skills or engage in friendly matches. With coaching
                            available for all skill levels, players elevate
                            their game while enjoying the camaraderie of fellow
                            tennis lovers.
                          </p>
                        </div>
                        <div className="col-lg-4 text-center order-1 order-lg-2">
                          <img
                            src="assets/img/specials-1.jpg"
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane" id="tab-2">
                      <div className="row">
                        <div className="col-lg-8 details order-2 order-lg-1">
                          <h3>Our football field</h3>
                          <p className="fst-italic">
                            Step onto our football field at L4C and embrace the
                            thrill of the game. From pickup matches to organized
                            tournaments, our field accommodates players of all
                            ages and abilities. With well manicured turf and
                            modern amenities, it's the perfect setting for
                            exhilarating matches and unforgettable moments of
                            sporting excellence.
                          </p>
                        </div>
                        <div className="col-lg-4 text-center order-1 order-lg-2">
                          <img
                            src="assets/img/specials-2.jpg"
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane" id="tab-3">
                      <div className="row">
                        <div className="col-lg-8 details order-2 order-lg-1">
                          <h3>Our volleyball court</h3>
                          <p className="fst-italic">
                            Feel the sand between your toes and the sun on your
                            skin at the beach volleyball field at L4C. Nestled
                            against the backdrop of breathtaking ocean views,
                            our sandy court beckons players for exhilarating
                            matches and laid-back beach vibes. Whether you're
                            spiking, setting, or diving, the energizing
                            atmosphere and pristine surroundings elevate every
                            game. With friendly competitions and social events,
                            it's not just about the game—it's about forging
                            connections and creating unforgettable memories in a
                            tropical paradise.
                          </p>
                        </div>
                        <div className="col-lg-4 text-center order-1 order-lg-2">
                          <img
                            src="assets/img/specials-3.jpg"
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane" id="tab-4">
                      <div className="row">
                        <div className="col-lg-8 details order-2 order-lg-1">
                          <h3>Our Basket-ball field</h3>
                          <p className="fst-italic">
                            At L4C, our basketball court is more than just a
                            venue—it's a sanctuary for hoops aficionados.
                            Whether shooting hoops solo or competing in intense
                            games with friends, players relish the excitement of
                            the game. With adjustable hoops and ample space,
                            it's where skills are honed, bonds are forged, and
                            memories are made.
                          </p>
                        </div>
                        <div className="col-lg-4 text-center order-1 order-lg-2">
                          <img
                            src="assets/img/specials-4.jpg"
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane" id="tab-5">
                      <div className="row">
                        <div className="col-lg-8 details order-2 order-lg-1">
                          <h3>Our Gym</h3>
                          <p className="fst-italic">
                            Experience fitness redefined at the L4C gym.
                            Equipped with state-of-the-art machines and guided
                            by expert trainers, our facility caters to all
                            fitness levels and goals. From strength training to
                            cardio workouts, members enjoy personalized routines
                            in a motivating environment. With access to
                            cutting-edge equipment and comprehensive amenities,
                            achieving peak fitness has never been more
                            rewarding.
                          </p>
                        </div>
                        <div className="col-lg-4 text-center order-1 order-lg-2">
                          <img
                            src="assets/img/specials-5.jpg"
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </section>
      {/* Fin SPORT AND LEISURES */}
    </React.Fragment>
  );
}

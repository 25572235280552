import React from "react";
import cultural from "../../../../assets/images/activities/Centre-culturel.jpeg";

export default function Cultural() {
  return (
    <React.Fragment>
      <section id="cultural-activities" className="cultural activities-details">
        <div className="container px-4">
          <div className="activities-details-title" data-aos="zoom-out">
            <h2>activity</h2>
            <p>CULTURAL ACTIVITIES</p>
            <div className="mt-3">
              <span>
                Immerse yourself in a kaleidoscope of cultural experiences with
                our dynamic line-up of events and activities. From art
                exhibitions to live performances, workshops, and festivals,
                there is always something exciting happening at our Cultural
                Department! L4C cultural centre serves as an educational
                resource, actively participating in local, national, and
                international events to promote cultural learning and
                understanding.
              </span>
            </div>
          </div>

          <div className="row">
            <div
              className="col-lg-12 mx-auto my-5"
              style={{
                background: `url(${cultural}) top center no-repeat`,
                backgroundSize: "cover",
                height: "78vh",
              }}
            ></div>

            <div className="col-md-7 mb-4">
              <ul className="cultural-list accordion" data-aos="fade-up">
                <li>
                  <a
                    data-bs-toggle="collapse"
                    className="collapsed"
                    data-bs-target="#cultural1"
                  >
                    <h4>Promotion of culture</h4>
                    <i className="bx bx-chevron-down icon-show"></i>
                    <i className="bx bx-x icon-close"></i>
                  </a>
                  <div
                    id="cultural1"
                    className="collapse show"
                    data-bs-parent=".cultural-list"
                  >
                    <p>
                      The centre promotes culture through exhibitions,
                      performances, concerts, workshops, masterclasses, and
                      other cultural events and activities, providing a space
                      for different kinds of stakeholders including students,
                      artists, tourists, historians, and churches.
                    </p>
                  </div>
                </li>
                <li>
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#cultural3"
                    className="collapsed"
                  >
                    <h4>Cultural exchange programs</h4>
                    <i className="bx bx-chevron-down icon-show"></i>
                    <i className="bx bx-x icon-close"></i>
                  </a>
                  <div
                    id="cultural3"
                    className="collapse"
                    data-bs-parent=".cultural-list"
                  >
                    <p>
                      We facilitate cultural exchange and interactions between
                      people from different cultural backgrounds for cultural
                      immersion experiences. hrough its multifaceted approach,
                      L4C cultural centre strives to enrich the cultural fabric
                      of the local community while ensuring that valuable
                      histories and memories are preserved for future
                      generations to cherish and enjoy.
                    </p>
                  </div>
                </li>

                <li>
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#cultural4"
                    className="collapsed"
                  >
                    <h4>Membership within the Cultural Centre</h4>
                    <i className="bx bx-chevron-down icon-show"></i>
                    <i className="bx bx-x icon-close"></i>
                  </a>
                  <div
                    id="cultural4"
                    className="collapse"
                    data-bs-parent=".cultural-list"
                  >
                    <p>
                      Membership within L4C cultural centre offers an array of
                      benefits, including unrestricted access to the archives
                      and the library, guided museum visits, preferential rates
                      on programs and events, exclusive invitations to
                      members-only gatherings, and a host of additional
                      privileges designed to enrich the cultural and
                      intellectual pursuits of our esteemed members.
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

import React from "react";
import RestaurantImage from "../../../../assets/images/services/restaurant.jpeg";

export default function Restaurant() {
  return (
    <React.Fragment>
      {/* RESTAURANT */}
      <section className="cultural activities-details">
        <div className="container mb-4">
          <div className="activities-details-title" data-aos="zoom-out">
            <p>RESTAURANT</p>
            <div className="mt-3">
              <span>
                Savor the flavors of Madagascar and beyond at our onsite
                restaurant, where culinary delights await your discerning
                palate. From locally sourced ingredients to international
                cuisine, our talented chefs craft exquisite dishes that
                tantalize the taste buds and leave you craving more.
              </span>
            </div>
          </div>

          <div className="row mb-5">
            <div className="col-md-12 position-relative">
              <div className="row">
                {/* OVERVIEW */}
                <div className="col-md-6 col-lg-5 mb-5">
                  <img
                    src={RestaurantImage}
                    alt="restaurant"
                    className="img-fluid rounded-4"
                  />
                </div>
                <div className="col-lg-1 d-none d-lg-block position-relative">
                  <div className="title-vertical d-flex justify-content-start"></div>
                </div>
                <div className="col-md-6 col-lg-5 section-md-t3 pb-5">
                  <div className="title-box-d">
                    <p className="title-d">
                      <span className="color-d">Overview</span>
                    </p>
                  </div>
                  <p className="color-text-a">
                    Our restaurant is an exclusive event space, perfect for
                    bespoke celebrations and gatherings, which benefits from the
                    splendid view of the Ranomafana lake and spa from this place
                    located on a small peak. Experience tranquility, and
                    unparalleled service at our unique venue, where every moment
                    becomes a cherished memory.
                  </p>
                </div>
                {/* Fin OVERVIEW */}

                {/* VARIOUS DINING OPTION */}
                <div className="col-md-6 section-md-t3 pb-5">
                  <div className="title-box-d">
                    <p className="title-d">
                      <span className="color-d">Various dining option</span>
                    </p>
                  </div>
                  <p className="color-text-a">
                    Indulge in a culinary journey at our restaurant! With
                    seating for up to 120 guests, we cater to every palate with
                    a diverse selection of European, Norwegian, and Malagasy
                    delicacies. Our commitment to quality shines through in
                    every dish, showcasing the finest locally sourced
                    ingredients, including fresh vegetables that contribute to
                    Antsirabe's renowned flavors. Whether it is breakfast,
                    lunch, coffee breaks, cocktails, or buffets, we serve up a
                    delightful array of menu options to satisfy every craving.
                    Join us for a dining experience that's sure to leave you
                    craving more!
                  </p>
                </div>
                {/* Fin VRIOUS DINIG OPTION */}

                {/* FOOD AT L4C */}
                <div className="col-md-6 section-md-t3 pb-5">
                  <div className="title-box-d">
                    <p className="title-d">
                      <span className="color-d">Food at L4C</span>
                    </p>
                  </div>
                  <p className="color-text-a">
                    Immerse yourself in the culinary delights of Lovasoa with
                    our curated dining experiences and food tours. Explore the
                    vibrant markets, sample traditional delicacies, and embark
                    on a gastronomic journey that celebrates the rich diversity
                    and flavours of Malagasy cuisine. Indulge in the luxury of
                    comfort and hospitality at our esteemed Guesthouse in
                    Lovasoa. Whether you are here for business or leisure, we
                    promise an unforgettable experience that exceeds your every
                    expectation. Book your stay with us today and discover the
                    true meaning of hospitality in the heart of Madagascar!
                  </p>
                </div>
                {/* Fin FOOD AT L4C */}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Fin RESTAURANT */}
    </React.Fragment>
  );
}

import React, { createContext, useCallback, useEffect, useMemo, useReducer, useRef, useState } from "react";
import { initialState, reducerService } from "../reducer/reducer";
import { toast } from "react-toastify";


const ContextService = createContext();

export default ContextService;

export const ContextProvider = ({ children }) => {
  //----------------------------------------
  //  I M P L E M E N T   U S E - R E D U C E R 
  //----------------------------------------
  const [state, dispatch] = useReducer(reducerService, initialState);
  
  //----------------------------------------
  //  I M P L E M E N T   U S E - R E F 
  //----------------------------------------
  // booking form
  const bookingFormRef = useRef();
  // get in touch form
  const getInTouchFormRef = useRef();
  
  //----------------------------------------
  //  S T A T E S 
  //----------------------------------------
  // activity has a default value: library (string)
  const [activity, setActivity] = useState("LIBRARY");
  // activity has a default value: library (string)
  const [service, setService] = useState("CONFERENCE")
  // loading button booking form: boolean
  const [loadingSend, setLoadingSend] = useState(false);
  // loading button get in touch: boolean
  const [loadingGetinTouchBtn, setLoadingGetinTouchBtn] = useState(false);
  // window scrollY
  const [windowY, setWindowY] = useState(window.scrollY)

  

  //--------------------------------------
  // D  O C U M E N T S
  //--------------------------------------
  // ACTIVITIES --------------------------
  // library
  const activityLibrary = document.querySelector("#activity-library");
  // archive
  const activityArchive = document.querySelector("#activity-archive");
  // museum
  const activityMuseum = document.querySelector("#activity-museum");
  // music school
  const activityMusicSchool = document.querySelector("#activity-music-school");
  // studio
  const activityStudio = document.querySelector("#activity-studio");
  // cultural
  const activityCultural = document.querySelector("#activity-cultural");
  // language school
  const activityLanguageSchool = document.querySelector("#activity-language-school");
  // exchange
  const activityExchange = document.querySelector("#activity-exchange");
  
  // CONFERENCE --------------------------
  const serviceConference = document.querySelector("#service-conference");
  // accomodation
  const serviceAccomodation = document.querySelector("#service-accomodation");
  // transport
  const serviceTransport = document.querySelector("#service-transport");
  // soprt & leisures
  const serviceSport = document.querySelector("#service-sport-leisures");
  // restaurant
  const serviceRestaurant = document.querySelector("#service-restaurant");
  // cultural events
  const serviceCultural = document.querySelector("#service-cultural");


  //----------------------------------------
  //  F U N C T I O N S 
  //----------------------------------------
  // SUCCESS Toastify message
  const successMessage = useCallback((message) => {
    toast.success(message);
  }, []);
  // ERROR Toastify message
  const errorMessage = useCallback((message) => {
    toast.error(message);
  }, []);

  
  // activity to LIBRARY
  const swapActivityToLibrary = useCallback(() => {
    if (activityLibrary) {
      activityLibrary.classList.add("icon-box-active");
      activityArchive.classList.remove("icon-box-active");
      activityMuseum.classList.remove("icon-box-active");
      activityMusicSchool.classList.remove("icon-box-active");
      activityStudio.classList.remove("icon-box-active");
      activityCultural.classList.remove("icon-box-active");
      activityLanguageSchool.classList.remove("icon-box-active");
      activityExchange.classList.remove("icon-box-active");
    }
    setActivity("LIBRARY")
  }, [
    activityLibrary,
    activityArchive,
    activityMuseum,
    activityMusicSchool,
    activityStudio,
    activityCultural,
    activityLanguageSchool,
    activityExchange,
  ])

  // activity to ARCHIVE
  const swapActivityToArchive = () => {
    if (activityArchive) {
      activityArchive.classList.add("icon-box-active");
      activityLibrary.classList.remove("icon-box-active");
      activityMuseum.classList.remove("icon-box-active");
      activityMusicSchool.classList.remove("icon-box-active");
      activityStudio.classList.remove("icon-box-active");
      activityCultural.classList.remove("icon-box-active");
      activityLanguageSchool.classList.remove("icon-box-active");
      activityExchange.classList.remove("icon-box-active");
    }
    setActivity("ARCHIVE")
  }

  // activity to MUSEUM
  const swapActivityToMuseum = () => {
    if (activityMuseum) {
      activityMuseum.classList.add("icon-box-active");
      activityArchive.classList.remove("icon-box-active");
      activityLibrary.classList.remove("icon-box-active");
      activityMusicSchool.classList.remove("icon-box-active");
      activityStudio.classList.remove("icon-box-active");
      activityCultural.classList.remove("icon-box-active");
      activityLanguageSchool.classList.remove("icon-box-active");
      activityExchange.classList.remove("icon-box-active");
    }
    setActivity("MUSEUM")
  }

  // activity to MUSIC_SCHOOL
  const swapActivityToMusicSchool = () => {
     if (activityMuseum) {
       activityMusicSchool.classList.add("icon-box-active");
      activityMuseum.classList.remove("icon-box-active");
      activityArchive.classList.remove("icon-box-active");
      activityLibrary.classList.remove("icon-box-active");
      activityStudio.classList.remove("icon-box-active");
      activityCultural.classList.remove("icon-box-active");
      activityLanguageSchool.classList.remove("icon-box-active");
      activityExchange.classList.remove("icon-box-active");
    }
    setActivity("MUSIC_SCHOOL")
  }

  // activity to STUDIO
  const swapActivityToStudio = () => {
     if (activityStudio) {
       activityStudio.classList.add("icon-box-active");
       activityMusicSchool.classList.remove("icon-box-active");
      activityMuseum.classList.remove("icon-box-active");
      activityArchive.classList.remove("icon-box-active");
      activityLibrary.classList.remove("icon-box-active");
      activityCultural.classList.remove("icon-box-active");
      activityLanguageSchool.classList.remove("icon-box-active");
      activityExchange.classList.remove("icon-box-active");
    }
    setActivity("STUDIO")
  }

  // activity to CULTURAL
  const swapActivityToCultural = () => {
    if (activityCultural) {
      activityCultural.classList.add("icon-box-active");
      activityStudio.classList.remove("icon-box-active");
      activityMusicSchool.classList.remove("icon-box-active");
      activityMuseum.classList.remove("icon-box-active");
      activityArchive.classList.remove("icon-box-active");
      activityLibrary.classList.remove("icon-box-active");
      activityLanguageSchool.classList.remove("icon-box-active");
      activityExchange.classList.remove("icon-box-active");
    }
    setActivity("CULTURAL")
  }

  // activity to LANGUAGE_SCHOOL
  const swapActivityToLanguageSchool = () => {
    if (activityLanguageSchool) {
      activityLanguageSchool.classList.add("icon-box-active");
      activityCultural.classList.remove("icon-box-active");
      activityStudio.classList.remove("icon-box-active");
      activityMusicSchool.classList.remove("icon-box-active");
      activityMuseum.classList.remove("icon-box-active");
      activityArchive.classList.remove("icon-box-active");
      activityLibrary.classList.remove("icon-box-active");
      activityExchange.classList.remove("icon-box-active");
    }
    setActivity("LANGUAGE_SCHOOL")
  }

  // activity to EXCHANGE
  const swapActivityToExchange = () => {
     if (activityExchange) {
       activityExchange.classList.add("icon-box-active");
      activityLanguageSchool.classList.remove("icon-box-active");
      activityCultural.classList.remove("icon-box-active");
      activityStudio.classList.remove("icon-box-active");
      activityMusicSchool.classList.remove("icon-box-active");
      activityMuseum.classList.remove("icon-box-active");
      activityArchive.classList.remove("icon-box-active");
      activityLibrary.classList.remove("icon-box-active");
    }
    setActivity("EXCHANGE")
  }

  // -------------------------------------------
  // service to CONFERENCE
  const swapServiceToConference = () => {
     if (serviceConference) {
      serviceConference.classList.add("icon-box-active");
      serviceAccomodation.classList.remove("icon-box-active");
      serviceTransport.classList.remove("icon-box-active");
      serviceSport.classList.remove("icon-box-active");
      serviceRestaurant.classList.remove("icon-box-active");
      serviceCultural.classList.remove("icon-box-active");
    }
    setService("CONFERENCE")
  }

  // service to ACCOMODATION
  const swapServiceToAccomodation = () => {
     if (serviceAccomodation) {
       serviceAccomodation.classList.add("icon-box-active");
      serviceConference.classList.remove("icon-box-active");
      serviceTransport.classList.remove("icon-box-active");
      serviceSport.classList.remove("icon-box-active");
      serviceRestaurant.classList.remove("icon-box-active");
      serviceCultural.classList.remove("icon-box-active");
    }
    setService("ACCOMODATION")
  }

  // service to TRANSPORT
  const swapServiceToTransport = () => {
     if (serviceTransport) {
       serviceTransport.classList.add("icon-box-active");
       serviceAccomodation.classList.remove("icon-box-active");
      serviceConference.classList.remove("icon-box-active");
      serviceSport.classList.remove("icon-box-active");
      serviceRestaurant.classList.remove("icon-box-active");
      serviceCultural.classList.remove("icon-box-active");
    }
    setService("TRANSPORT")
  }

  // service to SPORT
  const swapServiceToSport = () => {
     if (serviceSport) {
       serviceSport.classList.add("icon-box-active");
       serviceTransport.classList.remove("icon-box-active");
       serviceAccomodation.classList.remove("icon-box-active");
      serviceConference.classList.remove("icon-box-active");
      serviceRestaurant.classList.remove("icon-box-active");
      serviceCultural.classList.remove("icon-box-active");
    }
    setService("SPORT")
  }

  // service to RESTAURANT
  const swapServiceToRestaurant = () => {
     if (serviceRestaurant) {
       serviceRestaurant.classList.add("icon-box-active");
       serviceSport.classList.remove("icon-box-active");
       serviceTransport.classList.remove("icon-box-active");
       serviceAccomodation.classList.remove("icon-box-active");
      serviceConference.classList.remove("icon-box-active");
      serviceCultural.classList.remove("icon-box-active");
    }
    setService("RESTAURANT")
  }

  // service to CULTURAL 
  const swapServiceToCultural = () => {
     if (serviceCultural) {
       serviceCultural.classList.add("icon-box-active");
       serviceRestaurant.classList.remove("icon-box-active");
       serviceSport.classList.remove("icon-box-active");
       serviceTransport.classList.remove("icon-box-active");
       serviceAccomodation.classList.remove("icon-box-active");
      serviceConference.classList.remove("icon-box-active");
    }
    setService("CULTURAL")
  }
  
  // reset window Y = o
  const resetWindowY = useCallback(() => { 
    window.scrollTo(0, 0);
    setWindowY(0);
   }, [])
  
  
  //----------------------------------------
  //  R E Q U E S T S
  //----------------------------------------
  // Get employees
  const getEmployees = useCallback(async () => { 
    // request
    const response = await fetch(`${state.baseUrl}/employees`, {
      method: 'GET',
      headers: {"Content-Type": "application/json"}
    })
    const data = response.json();
    if (response.status === 200) { 
      if (data) dispatch({type:"GET_EMPLOYEES", payload: {employees:data}});
    } else {
      console.log(response);
    }
   },[])
  
  // Save booking
  const saveBooking = useCallback(async (e) => {
    e.preventDefault();
    // loading boutton
    setLoadingSend(true);
    
    // insert data 
    const dataBooking = {
      first_name: e.target.firstname.value,
      last_name: e.target.lastname.value,
        address1: e.target.streetAddress.value,
      // address2: e.target.addressLine.value,
      city: e.target.city.value,
      province: e.target.state.value,
      zip_code: e.target.postalCode.value,
      phone: e.target.phone.value,
      email: e.target.email.value,
      // request: e.target.firstname.value,
      // first_name: e.target.firstname.value
    }
    
    // const response = await fetch(`${state.baseUrl}/booking`, {
    //   method: "POST",
    //   headers: { "Content-Type": "application/json" },
    //   body: JSON.stringify(dataBooking)
    // })
    // const data = await response.json();
    
    if (dataBooking) {
      // This block will be executed in 3s
      setTimeout(() => {
        // put loading button to false
        setLoadingSend(false)
        // clear the booking form
        bookingFormRef.current.reset();
        // message: successfully notification
        successMessage("Booking successfully !");
      }, 3000);
    } else {
      // put loading button to false
      setLoadingSend(false)
      // message: error notification
      errorMessage("There is an error, please try again!");
    }
    
  },[])

  // Save get in touch
  const saveGetInTouch = useCallback(async (e) => {
    e.preventDefault();
    // put the loading button to true
    setLoadingGetinTouchBtn(true);
    //request
    // const response = await fetch(`${state.baseUrl}`, {
    //   method: 'POST',
    //   headers: { "Content-Type": "application/json" },
    //   body: JSON.stringify({email: e.target.email.value})
    // })
    // const data = await response.json();
    // if (response.status === 200) {
    if (e.target.email.value) {
      // This block will be executed in 3s
      setTimeout(() => {
        // clear the get in touch form
        getInTouchFormRef.current.reset();
        // put loading button to false
        setLoadingGetinTouchBtn(false)
        // message: successfully notification
        successMessage("Email was sent successfully!");
      }, 3000);
    } else { 
      // put loading button to false
      setLoadingGetinTouchBtn(false)
      // message: error notification
      errorMessage("Error was comming, please try again!");
    }
    },[successMessage, errorMessage])
  
  /*----------------------------------------
    I M P L E M E N T   U S E - E F F E C T 
  -----------------------------------------*/ 
  // useEffect(() => {
  //     setTimeout(() => {
  //       getEmployees
  //     }, 3000);
  // }, [getEmployees])
  
    
  /*----------------------------------------
    D A T A 
  -----------------------------------------*/ 
  const data = useMemo(() => {
    return {
      // STATES --------------------
      activity,
      bookingFormRef,
      getInTouchFormRef, 
      loadingSend,
      loadingGetinTouchBtn,
      service,
      windowY,

     

      // FUNCTIONS -----------------
      resetWindowY,
      swapActivityToLibrary,
      swapActivityToArchive,
      swapActivityToMuseum,
      swapActivityToMusicSchool,
      swapActivityToStudio,
      swapActivityToCultural,
      swapActivityToLanguageSchool,
      swapActivityToExchange,

      // services
      swapServiceToConference,
      swapServiceToAccomodation,
      swapServiceToTransport,
      swapServiceToSport,
      swapServiceToRestaurant,
      swapServiceToCultural,

      // REQUESTS ------------------
      saveBooking,
      saveGetInTouch,
    };
  }, [
    // STATES ----------------------
    activity,
    bookingFormRef,
    getInTouchFormRef, 
    service,
    windowY,
    loadingSend,
    loadingGetinTouchBtn,
    
    // FUNCTIONS -------------------
    resetWindowY,
    swapActivityToLibrary,
    swapActivityToArchive,
    swapActivityToMuseum,
    swapActivityToMusicSchool,
    swapActivityToStudio,
    swapActivityToCultural,
    swapActivityToLanguageSchool,
    swapActivityToExchange,

    // services
    swapServiceToConference,
    swapServiceToAccomodation,
    swapServiceToTransport,
    swapServiceToSport,
    swapServiceToRestaurant,
    swapServiceToCultural,

    // REQUESTS ------------------
    saveBooking,
    saveGetInTouch,
      
  ]);

  return (
    <ContextService.Provider value={data}>{children}</ContextService.Provider>
  );
};
